import * as React from "react"
import {Link} from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import LogoKopf from "../svg/Kopf.svg";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
      <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
          <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
              <div className="flex-shrink-0 flex justify-center">
                  <Link to="/" className="inline-flex">
                      <span className="sr-only">di IT-Consulting</span>
                      <LogoKopf className="w-auto h-64 sm:h-24" />
                  </Link>
              </div>
              <div className="py-16">
                  <div className="text-center">
                      <p className="text-sm font-semibold text-diit uppercase tracking-wide">404 error</p>
                      <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Seite nicht gefunden.</h1>
                      <p className="mt-2 text-base text-gray-500">Entschuldigung, wir konnten die gesuchte Seite leider nicht finden.</p>
                      <div className="mt-6">
                          <Link to="/" className="text-base font-medium text-diit hover:underline">
                              Zurück zum Start<span aria-hidden="true"> &rarr;</span>
                          </Link>
                      </div>
                  </div>
              </div>
          </main>
      </div>
  </Layout>
)

export default NotFoundPage
